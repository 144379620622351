import {createRouter,createWebHashHistory} from "vue-router";
// import * as path from "path";
const routes=[
    // { path:"",
    //     name:"App" ,
    //     component: () => import('./App.vue')
    // },
    {
        path: '/login',
        name: 'Login_page',
        component: () => import('@/views/auth/Login.vue')
    },
    //  print
    {
        path: '/output_machine/:id/:date',
        name: 'PrintOutput',
        component: () => import('@/views/machine/PrintOutput.vue')
    },
    {
        path: '/print_producing',
        name: 'printProducing',
        component: () => import('@/views/machine/PrintProducing.vue')
    },
    {
        path: '/print_completing',
        name: 'PrintCompleting',
        component: () => import('@/views/order_actions/PrintCompleting.vue')
    },
    {
        path: '/print_sewing',
        name: 'PrintSewing',
        component: () => import('@/views/order_actions/PrintSewing.vue')
    },
    {
        path: '/print_essential',
        name: 'PrintEssential',
        component: () => import('@/views/order_actions/PrintEssential.vue')
    },
    {
        path: '/print_order_control/:id',
        name: 'PrintOrderControl',
        component: () => import('@/views/order_actions/PrintOrderControl')
    },
    {
        path: '/print_delivered/:id/:sack/:number',
        name: 'PrintDelivered',  
        component: () => import('@/views/order_actions/PrintDelivered')
    },


    { path:"/",
        name:"Dashboard_page" ,
        component: () => import('@/layouts/Dashboard'),
        children:[
            //  users
            {
                path:'/users',
                name:'ManageUser',
                component:()=>import('./views/users/ManageUser'),
    
            },
            //  roles
            {
                path:'/roles',
                name:'ManageRole',
                component:()=>import('./views/roles/ManageRole'),
    
            },
            //  products
            {
                path:'/products',
                name:'ManageProduct',
                component:()=>import('./views/products/ManageProduct'),
    
            },
            {
                path:'/materials',
                name:'ManageMaterial',
                component:()=>import('./views/products/ManageMaterial'),
    
            },
            {
                path:'/product_size',
                name:'ManageSize',
                component:()=>import('./views/products/ManageSize'),
    
            },
            {
                path:'/essential',
                name:'ManageEssential',
                component:()=>import('./views/products/ManageEssential'),
    
            },
            {
                path:'/pood_materials',
                name:'ManagePoodMaterial',
                component:()=>import('./views/products/ManagePoodMaterial'),
    
            },
            {
                path:'/patterns',
                name:'ManagePattern',
                component:()=>import('./views/products/ManagePattern'),
    
            },
            {
                path:'/pattern/:id',
                name:'EditPattern',
                component:()=>import('./views/products/EditPattern'),
    
            },
            //  warehouses
            {
                path:'/warehouses',
                name:'ManageWarehouse',
                component:()=>import('./views/warehouses/ManageWarehouse'),
    
            },
            {
                path:'/show_stock_warehouse/:id',
                name:'ShowStockWarehouse',
                component:()=>import('./views/warehouses/ShowStockWarehouse'),
    
            },
            {
                path:'/search_code_in_warehouse',
                name:'SearchWarehouseCode',
                component:()=>import('./views/warehouses/SearchWarehouseCode'),
    
            },
            // workshop 
            {
                path:'/workshop',
                name:'ManageWorkshop',
                component:()=>import('./views/workshop/ManageWorkshop'),
    
            },
            // Machine
            {
                path:'/machine',
                name:'ManageMachine',
                component:()=>import('./views/machine/ManageMachine'),
    
            },

            {
                path:'/list_of_producing',
                name:'ListOfProducing',
                component:()=>import('./views/machine/ListOfProducing'),
    
            },
            // Customer
            {
                path:'/customers',
                name:'ManageCustomer',
                component:()=>import('./views/customers/ManageCustomer'),
    
            },
            // Orders
            {
                path:'/submit_order',
                name:'SubmitOrder',
                component:()=>import('./views/orders/SubmitOrder'),
    
            },
            {
                path:'/process_order',
                name:'ProcessOrder',
                component:()=>import('./views/orders/ManageProcessOrder'),
    
            },
            //  actinos for orders
            {
                path:'/order_control/:id',
                name:'OrderControl',
                component:()=>import('./views/order_actions/OrderControl'),
    
            },
            {
                path:'/need_to_produce_Control/:id/:order_id',
                name:'NeedToProduceControl',
                component:()=>import('./views/order_actions/NeedToProduceControl'),
    
            },
            {
                path:'/produced_Control/:id/:order_id',
                name:'ProducedControl',
                component:()=>import('./views/order_actions/ProducedControl'),
    
            },
            {
                path:'/completing_Control/:id/:order_id',
                name:'CompletingControl',
                component:()=>import('./views/order_actions/CompletingControl'),
    
            },

            {
                path:'/need_to_sewing_Control/:id/:order_id',
                name:'NeedToSewingControl',
                component:()=>import('./views/order_actions/NeedToSewingControl'),
    
            },

            {
                path:'/sewing_Control/:id/:order_id',
                name:'SewingControl',
                component:()=>import('./views/order_actions/SewingControl'),
    
            },
            {
                path:'/reserved_Control/:id/:order_id',
                name:'ReservedControl',
                component:()=>import('./views/order_actions/ReservedControl'),
    
            },

            {
                path:'/show_ready_to_deliver_details/:id/:sack_code',
                name:'ShowReadyToDeliver',
                component:()=>import('./views/order_actions/ShowReadyToDeliver'),
    
            },

            {
                path:'/show_ready_to_deliver_for_customer/:code',
                name:'ShowReadyToDeliverWithCode',
                component:()=>import('./views/order_actions/ShowReadyToDeliverWithCode'),
    
            },

            {
                path:'/show_sack_detail/:code/:customer',
                name:'SackDetail',
                component:()=>import('./views/order_actions/SackDetail'),
    
            },
            {   
                path:'/show_delivered/:id/:sack/:number',     
                name:'ShowDelivered',
                component:()=>import('./views/order_actions/ShowDelivered'),
    
            },
            {
                path:'/show_sack_delivered/:code/:number/:customer_id',
                name:'SackDeliveredDetail',
                component:()=>import('./views/order_actions/SackDeliveredDetail'),
    
            },
            // Manage Order
            {
                path:'/order_list',
                name:'OrderList',
                component:()=>import('./views/orders/OrderList'),
    
            },
            {
                path:'/edit_order/:id',
                name:'EditOrder',
                component:()=>import('./views/order_actions/EditOrder'),
    
            },
            {
                path:'/progress_order_product_details/:id',
                name:'OrderProductDetails',
                component:()=>import('./views/order_actions/OrderProductDetails'),
    
            },
            






        ]

    },
    // { path:"/ss",
    //     name:"ss_page" ,
    //     component: () => import('@/components/ss_ff.vue') 
    // },

]



const router=createRouter({
    history:createWebHashHistory(),
    base: process.env.BASE_URL,
    routes
})
    // router.beforeEach((to, from, next) => {
//     const userInfo = JSON.parse(localStorage.getItem('user_info'))
//     const userPermissions = JSON.parse(localStorage.getItem('user_permissions'))
//     // const authenticationRouteNames = ['Login']
//
//     if (userInfo === null && to.meta.auth) { // check for pages need authentication
//         next('/login')
//     }
//         //else if (userInfo !== null && authenticationRouteNames.includes(to.name)) { // check if logged in user requests for authentication pages
//         //   next('/')
//     //}
//     else  if (to.meta.allowedPermissions) { // check routes accessibility based on user permissions
//         if (userPermissions === null) {
//             next('/')
//         } else if (to.meta.allowedPermissions.filter(val => userPermissions.includes(val)).length === 0) {
//             next('/')
//         } else {
//             next()
//         }
//     } else {
//         next()
//     }
// })
//

export default router;