import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      pc_value: JSON.parse(localStorage.getItem('pc_value')) || [], // Retrieve from localStorage or use an empty array
      ps_value: JSON.parse(localStorage.getItem('ps_value')) || [], // Retrieve from localStorage or use an empty array
    };
  },
  mutations: {
    updateArrayCompleting(state, newArray) {
      state.pc_value = newArray;
      localStorage.setItem('pc_value', JSON.stringify(newArray)); // Store in localStorage
    },
    updateArraySewing(state, newArray) {
      state.ps_value = newArray;
      localStorage.setItem('ps_value', JSON.stringify(newArray)); // Store in localStorage
    },
  },
  actions: {
    updateArrayCompleting(context, newArray) {
      context.commit('updateArrayCompleting', newArray);
    },
    updateArraySewing(context, newArray) {
      context.commit('updateArraySewing', newArray);
    },
  },
  getters: {
    getArray(state) {
      return state.pc_value;
    },
  },
});

export default store;
