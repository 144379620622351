<template>
  <router-view/>
  <notifications/>
</template>

<script>
import 'boxicons/css/boxicons.min.css'
import 'vue-select/dist/vue-select.css';
export default {
  name: "App",
  methods: {
    // search(val) {
      
    // }
  },
}
</script>
<style>
body{
  padding: 0 !important;
}

</style>

